import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { Helmet } from "react-helmet";
import SectionPromo2 from "components/SectionPromo2";
import Questionare from "components/Questionare";

import QuestionA from "images/q2-a.png";
import QuestionB from "images/q2-b.png";
import QuestionC from "images/q2-c.png";

import QuestionHead from "images/question-head-2.png";
import useChatbot from "chatbot/Chatbot";
import { useEffect } from "react";

const QUESTION_2 = [
  {
    id: 1,
    ques: "size",
    desc: `A. เล็ก (32"-43")`,
    image: QuestionA,
    color: "bg-indigo-50",
    link: "/question-3",
  },
  {
    id: 2,
    ques: "size",
    desc: `B. กลาง (50"-58")`,
    image: QuestionB,
    color: "bg-slate-100/80",
    link: "/question-3",
  },
  {
    id: 3,
    ques: "size",
    desc: `C. ใหญ่ (60"-85")`,
    image: QuestionC,
    color: "bg-violet-50",
    link: "/question-3",
  }
];

function PageQuestion2() {
  const chatbot = useChatbot();

  useEffect(() => {
    chatbot.textTriggerNlp('Introduce TV')
  }, [])

  return (
    <div className="nc-PageQuestion2 nc-PageHome2 relative overflow-hidden">
      <Helmet>
        <title>Lotus E-catalog</title>
      </Helmet>
      <div className="container relative space-y-24 mb-0 lg:space-y-32 lg:mb-32" id="homepage-container">
        {/* SECTION */}
        <div className="pb-16 relative py-8 lg:py-16">
          <BackgroundSection />
          <Questionare
            questionHead={QuestionHead}
            data={QUESTION_2}
          />
        </div>
      </div>
    </div>
  );
}

export default PageQuestion2;
