const ue = { cloudminds: {} }, cloudiaConfig = null
const UE4 = {
    /**
     * 初始化SDK 传入onReceiveCmd, 用于接收nlp, a, reload等命令
     * @param onReceiveCmd
     * @param config
     */
    init(
        onReceiveCmd,
        config = {
            jsHandleTts: false,
            jsHandleState: false
        }
    ) {
        window.sendCmd = function (cmd, params) {
            if (cmd === "onBind") {
                ue.cloudminds.hue("onstart", JSON.stringify(config));
                console.info("onStart event sent");
                window.cloudiaConfig = params;
                if (params && params.SweetGirlClothes) {
                    cloudiaConfig.girls = new Map();
                    cloudiaConfig.sweetGirlClothes = params.SweetGirlClothes.replace(" ", "").split(",");
                    cloudiaConfig.girls.set("SweetGirl", cloudiaConfig.sweetGirlClothes);
                    cloudiaConfig.allClothes = cloudiaConfig.girls.get("SweetGirl");
                    if (params.BusinessGirlClothes) {
                        cloudiaConfig.businessGirlClothes = params.BusinessGirlClothes.replace(" ", "").split(",");
                        cloudiaConfig.girls.set("BusinessGirl", cloudiaConfig.businessGirlClothes);
                    }
                    if (params.AncientGirlClothes) {
                        cloudiaConfig.ancientGirlClothes = params.AncientGirlClothes.replace(" ", "").split(",");
                        cloudiaConfig.girls.set("AncientGirl", cloudiaConfig.ancientGirlClothes);
                    }
                }
            }
            return onReceiveCmd(cmd, params);
        };
    },
    reloadClothes() {
        if (cloudiaConfig && cloudiaConfig.SweetGirlClothes) {
            cloudiaConfig.allClothes = cloudiaConfig.girls.get(cloudiaConfig.currentCharacter);
        }
    },
    isNotValidNum(value) {
        return !/(^[-0-9][0-9]*(.[0-9]+)?)$/.test(value);
    },
    isEmpty(obj) {
        return typeof obj === "undefined" || obj == null || obj === "";
    },
    /**
     * 显示toast
     * @param msg toast内容
     */
    showToast(msg) {
        if (ue.cloudminds) {
            if (this.isEmpty(msg)) {
                console.warn("Invalid call:showToast");
                return false;
            }
            ue.cloudminds.hue("showtoast", msg);
            return true;
        }
        return false;
    },
    /**
     * 显示长toast
     * @param msg toast内容
     */
    showLongToast(msg) {
        if (ue.cloudminds) {
            if (this.isEmpty(msg)) {
                console.warn("Invalid call:showLongToast");
                return false;
            }
            ue.cloudminds.hue("showlongtoast", msg);
            return true;
        }
        return false;
    },
    /**
     * 语音播报
     * @param text 播报的内容
     */
    playTts(text) {
        if (ue.cloudminds) {
            if (this.isEmpty(text)) {
                console.warn("Invalid call:playTts");
                return false;
            }
            ue.cloudminds.hue("playtts", text);
            return true;
        }
        return false;
    },
    /**
     * 修改浏览器位置
     * @param left 浏览器距离屏幕左边的位置
     * @param top 浏览器距离屏幕上边的位置
     * @param right 浏览器距离屏幕右边的位置
     * @param bottom 浏览器距离屏幕下边的位置
     */
    updateBrowserLocation(left, top, right, bottom) {
        if (
            this.isNotValidNum(left) ||
            this.isNotValidNum(top) ||
            this.isNotValidNum(right) ||
            this.isNotValidNum(bottom)
        ) {
            console.error("updateBrowserLocation 数据不合法");
            return false;
        }
        if (ue.cloudminds) {
            // 蓝图端用的是","分隔方案
            ue.cloudminds.hue("updatebrowserlocation", left + ", " + top + ", " + right + ", " + bottom);
            return true;
        }
        return false;
    },
    /**
     * 修改虚拟人物位置
     * @param locationX X坐标值
     * @param locationY Y坐标值
     * @param locationZ Z坐标值
     * @param persistence 是否持久化保存
     */
    updateCharacterLocation(locationX, locationY, locationZ, persistence = false) {
        if (this.isNotValidNum(locationX) || this.isNotValidNum(locationY) || this.isNotValidNum(locationZ)) {
            console.error("updateCharacterLocation 数据不合法");
            return false;
        }
        if (ue.cloudminds) {
            ue.cloudminds.hue(
                "updatecharacterlocation",
                locationX + "#$*&@" + locationY + "#$*&@" + locationZ + "#$*&@" + persistence
            );
            return true;
        }
        return false;
    },
    /**
     * 修改虚拟人物旋转角度
     * @param rotationP P坐标值
     * @param rotationY Y坐标值
     * @param rotationR R坐标值
     * @param persistence 是否持久化保存
     */
    updateCharacterRotation(rotationP, rotationY, rotationR, persistence = false) {
        if (this.isNotValidNum(rotationP) || this.isNotValidNum(rotationY) || this.isNotValidNum(rotationR)) {
            console.error("updateCharacterRotation 数据不合法");
            return false;
        }
        if (ue.cloudminds) {
            ue.cloudminds.hue(
                "updatecharacterrotation",
                rotationP + "#$*&@" + rotationY + "#$*&@" + rotationR + "#$*&@" + persistence
            );
            return true;
        }
        return false;
    },
    /**
     * 播放动画
     * @param name 动画名称
     * @param status 动画状态
     */
    playMotion(name, status = "") {
        if (ue.cloudminds) {
            if (this.isEmpty(name)) {
                console.warn("Invalid call:playMotion");
                return false;
            }
            if (status.length === 0) {
                ue.cloudminds.hue("playmotion", name);
            } else {
                ue.cloudminds.hue("playmotion", name + "#$*&@" + status);
            }
            return true;
        }
        return false;
    },
    /**
     * 设置表情
     * @param exp 表情名称
     * @param value 值
     */
    setExpression(exp, value) {
        if (ue.cloudminds) {
            if (this.isEmpty(value) || this.isEmpty(exp)) {
                console.warn("Invalid call:setExpression");
                return false;
            }
            ue.cloudminds.hue("setexpression", exp + "#$*&@" + value);
            return true;
        }
        return false;
    },
    /**
     * @param color 背景墙颜色
     */
    setBgWallColor(color) {
        if (ue.cloudminds) {
            ue.cloudminds.hue("setMapBgColor", color);
            return true;
        }
        return false;
    },
    /**
     * @param wall 背景墙资源名称
     */
    setBgWallResource(wall) {
        if (ue.cloudminds) {
            ue.cloudminds.hue("setMapBgResource", wall);
            return true;
        }
        return false;
    },
    /**
     * 设置是否由js接管tts播报，接管之后，app将不负责播报，转而由js负责调用palytts进行播报
     * @param enable
     */
    setJsPlayTtsEnabled(enable) {
        if (this.isEmpty(enable)) {
            console.warn("Invalid call:setJsPlayTtsEnabled");
            return false;
        }
        if (ue.cloudminds) {
            ue.cloudminds.hue("setjsplayttsenabled", enable.toString());
        }
        return true;
    },
    /**
     * 设置是否由js接管state，接管之后，app将不切换state
     * @param enable
     */
    setJsHandleStateEnabled(enable) {
        if (this.isEmpty(enable)) {
            console.warn("Invalid call:setJsHandleStateEnabled");
            return false;
        }
        if (ue.cloudminds) {
            ue.cloudminds.hue("setJsHandleStateEnabled", enable.toString());
            return true;
        }
        return false;
    },
    /**
     * 获取deviceId，调用成功之后会在onCmdReceived中收到deviceId的命令
     * @deprecated 请改用getRobotInfoConfig("RobotId")方法
     */
    getDeviceId() {
        if (ue.cloudminds) {
            ue.cloudminds.hue("getdeviceid");
            return true;
        }
        return false;
    },
    getRobotInfoConfig(key) {
        if (ue.cloudminds) {
            if (this.isEmpty(key)) {
                console.warn("Invalid call:getRobotInfoConfig");
                return false;
            }
            return ue.cloudminds.hueget("getrobotinfoconfig", key);
        }
        return new Promise(function () {
            return "";
        });
    },
    getGreetingMsg() {
        return this.getAppConfig();
    },
    getAppConfig() {
        if (ue.cloudminds) {
            return ue.cloudminds.hueget("getappconfig");
        }
        return new Promise(function () {
            return "";
        });
    },
    getCharacter() {
        if (ue.cloudminds) {
            return ue.cloudminds.hueget("getcharacterinfo");
        }
        return new Promise(function () {
            return "";
        });
    },
    setCharacter(cname) {
        if (this.isEmpty(cname)) {
            console.warn("Invalid call:setCharacter");
            return false;
        }
        if (ue.cloudminds) {
            ue.cloudminds.hue("setcharacter", cname);
            if (cloudiaConfig) {
                cloudiaConfig.currentCharacter = cname;
            }
            this.reloadClothes();
            return true;
        }
        return false;
    },
    setClothes(name) {
        if (cloudiaConfig && cloudiaConfig.allClothes && !cloudiaConfig.allClothes.indexOf(name) < 0) {
            console.warn("Invalid call:setClothes:" + name);
            return false;
        }
        if (ue.cloudminds) {
            ue.cloudminds.hue("setClothes", name);
            return true;
        }
        return false;
    },
    postMediaEvent(event) {
        if (this.isEmpty(event)) {
            console.warn("Invalid call:postMediaEvent");
            return false;
        }
        if (ue.cloudminds) {
            ue.cloudminds.hue("postmediaevent", event);
            return true;
        }
        return false;
    },
    setAsrWakeUpEnable(enable) {
        if (this.isEmpty(enable)) {
            console.warn("Invalid call:setAsrWakeUpEnable");
            return false;
        }
        if (ue.cloudminds) {
            ue.cloudminds.hue("setAsrWakeUpEnable", enable.toString());
            return true;
        }
        return false;
    },
    stopPlayTts(playOK = false) {
        if (ue.cloudminds) {
            ue.cloudminds.hue("stopTts", playOK.toString());
            return true;
        }
        return false;
    },
    setWalkGreetingEnable(enable) {
        if (this.isEmpty(enable)) {
            console.warn("Invalid call:setWalkGreetEnable");
            return false;
        }
        if (ue.cloudminds) {
            ue.cloudminds.hue("walkGreetEnable", enable.toString());
            return true;
        }
        return false;
    },
    setWallLocation(location) {
        if (this.isEmpty(location)) {
            console.warn("Invalid call:setWallLocation");
            return false;
        }
        if (ue.cloudminds) {
            ue.cloudminds.hue("setbgwalllocation", location);
            return true;
        }
        return false;
    },
    enterImmerseMode(immerse) {
        if (this.isEmpty(immerse)) {
            console.warn("Invalid call:enterImmerseMode");
            return false;
        }
        if (ue.cloudminds) {
            ue.cloudminds.hue("enterImmerseMode", immerse.toString());
            return true;
        }
        return false;
    },
    startVideoTalk() {
        if (ue.cloudminds) {
            ue.cloudminds.hue("starttalk");
            return true;
        }
        return false;
    },
    stopVideoTalk() {
        if (ue.cloudminds) {
            ue.cloudminds.hue("endtalk");
            return true;
        }
        return false;
    },
    textTriggerNlp(text) {
        if (this.isEmpty(text)) {
            console.warn("Invalid call:textTriggerNlp");
            return false;
        }
        if (ue.cloudminds) {
            ue.cloudminds.hue("texttriggernlp", text);
            return true;
        }
        return false;
    },
    setVolume(vol) {
        if (this.isNotValidNum(vol)) {
            console.warn("Invalid call:setVolume:" + vol);
            return false;
        }
        if (ue.cloudminds) {
            ue.cloudminds.hue("setvolume", vol.toString());
            return true;
        }
        return false;
    },
    getVolume() {
        if (ue.cloudminds) {
            return ue.cloudminds.hueget("getcurvolume");
        }
        return new Promise(function () {
            return "11";
        });
    },
    downloadFiles(tag, urls, deleteOldTag = true) {
        if (ue.cloudminds) {
            ue.cloudminds.hue("downloadfiles", tag + "#$*&@" + urls + "#$*&@" + deleteOldTag);
            return true;
        }
        return false;
    },
    updateCharacterScaleLevel(scaleLevel, persistence = false) {
        if (this.isNotValidNum(scaleLevel)) {
            console.warn("Invalid call:setVolume:" + persistence);
            return false;
        }
        if (ue.cloudminds) {
            ue.cloudminds.hue("setcharacterscale", scaleLevel + "#$*&@" + persistence);
            return true;
        }
    },
    clearWebCache() {
        if (ue.cloudminds) {
            ue.cloudminds.hue("clearwebcache");
            return true;
        }
        return false;
    },
    changeState(newstate) {
        if (ue.cloudminds) {
            ue.cloudminds.hue("changestate", newstate);
            return true;
        }
        return false;
    },
    setAsrEnable(enable) {
        if (ue.cloudminds) {
            ue.cloudminds.hue("setasrenable", enable.toString());
            return true;
        }
        return false;
    }
};

export default new Proxy(UE4, {
    get(target, action) {
        if (target[action] && typeof target[action] === 'function') {
            if (action === 'init') {
                return function (onReceiveCmd) {
                    window.addEventListener('message', function (event) {
                        //todo validate event origin
                        if (event.data && event.data.cmd) {
                            const { cmd, params } = event.data
                            (typeof onReceiveCmd === 'function') && onReceiveCmd(cmd, params)
                        }
                    }, false)
                }
            }
            return function () {
                console.log(`${new Date()}:[iframe Call UE4] ${action}: `, ...arguments)
                window.parent.postMessage({ FunctionName: action, Param: Array.from(arguments) }, '*')
            }
        }
        console.error(`iframe CallUE4: [${action}] api not exist`)
        return () => Promise.resolve()
    }
})

