import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { Helmet } from "react-helmet";
import Questionare from "components/Questionare";

import QuestionA from "images/q3-a.png";
import QuestionB from "images/q3-b.png";
import QuestionC from "images/q3-c.png";
import QuestionD from "images/q3-d.png";

import QuestionHead from "images/question-head-3.png";

const QUESTION = [
  {
    id: 1,
    ques: "feature",
    desc: `A. สั่งการด้วยเสียง`,
    image: QuestionA,
    color: "bg-indigo-50",
    link: "/product-list/ทีวี/0",
  },
  {
    id: 2,
    ques: "feature",
    desc: `B. ระบบ Android `,
    image: QuestionB,
    color: "bg-slate-100/80",
    link: "/product-list/ทีวี/0",
  },
  {
    id: 3,
    ques: "feature",
    desc: `C. ความชัด 8K`,
    image: QuestionC,
    color: "bg-violet-50",
    link: "/product-list/ทีวี/0",
  },
  {
    id: 4,
    ques: "feature",
    desc: `D. Design`,
    image: QuestionD,
    color: "bg-violet-50",
    link: "/product-list/ทีวี/0",
  }
];

function PageQuestion3() {
  return (
    <div className="nc-PageQuestion3 nc-PageHome2 relative overflow-hidden">
      <Helmet>
        <title>Lotus E-catalog</title>
      </Helmet>
      <div className="container relative space-y-24 mb-0 lg:space-y-32 lg:mb-32" id="homepage-container">
        {/* SECTION */}
        <div className="pb-16 relative py-8 lg:py-16">
          <BackgroundSection />
          <Questionare
            questionHead={QuestionHead}
            data={QUESTION}
            gridClassName="grid-cols-4"
          />
        </div>
      </div>
    </div>
  );
}

export default PageQuestion3;
