import React, { FC, useEffect, useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link, useLocation } from "react-router-dom";
import explore1Svg from "images/collections/explore1.svg";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import useQuery from "hooks/useQuery";

export interface CardCategory6Props {
  className?: string;
  featuredImage?: string;
  bgSVG?: string;
  name: string;
  desc: string;
  color?: string;
  link?: string;
  ques?: string;
  id?: number;
}

const CardCategory6: FC<CardCategory6Props> = ({
  className = "",
  featuredImage = ".",
  name,
  desc,
  link = "/",
  ques,
  id
}) => {
  const query = useQuery();

  const [goToLink, setGoToLink] = useState<string>("/");

  useEffect(() => {
    let pick = query.get("pick");
    if (!pick) {
      setGoToLink(`/?pick=${desc}_`);
      return;
    };

    setGoToLink(`/?pick=${pick + id}`);
  }, [query.get("pick")]);

  // useEffect(() => {

  // }, [query.get("pick")])

  return (
    <Link
      className={`drop-shadow-xl nc-CardCategory6 w-full rounded-lg overflow-hidden bg-white dark:bg-neutral-900 group transition-shadow ${className}`}
      data-nc-id="CardCategory6"
      to={goToLink}
    // onClick={window["alertHello"]}
    >
      <div>
        <NcImage
          src={featuredImage}
          containerClassName={`w-auto h-18 overflow-hidden z-0`}
          className="object-cover w-full h-full p-0"
        />
      </div>

      <div className="flex flex-col items-center my-2 mx-2 text-center">
        <Link
          to={goToLink}
          className="flex items-center font-medium transition-colors"
        >
          <span className="option-text leading-tight">{desc}</span>
          {/* <ArrowRightIcon className="w-4 h-4 ml-2.5" /> */}
        </Link>
      </div>
    </Link>
  );
};

export default CardCategory6;
