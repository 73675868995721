import { useEffect, useState } from "react";
import chatbotSdk from "./cloudia-sdk-v1.4.1.5-iframe";

// export default function Chatbot() {

//     useEffect(() => {
//         chatbotSdk.init((cmd: any, params: any) => {
//           console.log(`receive cmd=${cmd},params=${params}`);
//         });
//         // window.UE4.init()
//       }, []);
// }

export default function useChatbot() {

    const [chatbot] = useState<any>(chatbotSdk);

    function trigger(functionName: any, params: any) {
        chatbot[functionName] && chatbot[functionName](params);
    }

    function handleMsgFromUe4(cmd: any, body: any) {
        if (cmd === "nlp") {
            const { question, answer } = body;
            if (question && question.text === "I need recommendation") {
                trigger("enterImmerseMode", true);
            }
        } else if (cmd === "setState") {
            const { state } = body;
            if (state === "IDLE") {
                window.location.replace("/");
            }
        }
    }

    useEffect(() => {
        chatbot.init((cmd: any, params: any) => {
            console.log(`receive cmd=${cmd},params=${params}`);
            let body;
            try {
                body = JSON.parse(params);
            } catch (e) {
                body = params;
            }
            handleMsgFromUe4(cmd, body);
        });
    }, []);

    return chatbot;
};