import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Product } from "data/data";
import { FC, useEffect, useState } from "react";

// const DEMO_DATA = [
//   {
//     name: "คำอธิบาย",
//     content:
//       "เปิดประสบการณ์การรับชมความบันเทิงของคุณให้ถึงขีดสุดด้วยหน้าจอทีวีดีไซน์บางเฉียบผ่าน แอลอีดีทีวี จาก SAMSUNG เหนือชั้นด้วยเทคโนโลยี PurColor ให้ภาพที่สีสันสวยงามเป็นธรรมชาติ พร้อมมอบความสนุกให้กับคุณได้อย่างเต็มอิ่มไปกับเอฟเฟกต์เสียงรอบทิศทางโดยไม่ต้องปิดลำโพงทีวี เพื่อให้คุณได้รับชมคอนเทนต์โปรดผ่านทีวีได้อย่างเต็มอรรถรส และเพลิดเพลินไปกับความละเอียดภาพที่คมชัด",
//   },
//   {
//     name: "ข้อมูลจำเพาะ",
//     content: `<ul class="list-disc list-inside leading-7">
//     <li>ความกว้าง (ซม.) 108.1</li>
//     <li>ความสูง (ซม.) 67</li>
//     <li>ความละเอียดหน้าจอ 4K (2160p)</li>
//     <li>HDMI (ช่อง) 3</li>
//     <li>ชนิดหน้าจอ LED</li>
//   </ul>`,
//   },
// ];

interface Props {
  panelClassName?: string;
  product: Product
}

const AccordionInfo: FC<Props> = ({
  panelClassName = "p-0 pt-0 last:pb-0 text-slate-600 text-sm dark:text-slate-300 leading-6",
  product
}) => {

  const [details, setDetails] = useState<any>([]);

  useEffect(() => {
    let details = []
    let desc = {
      name: "คำอธิบาย",
      content: product.description.split("\n"),
    }
    details.push(desc)

    if (product.dimension) {
      let dimension = {
        name: "ขนาดสินค้า",
        content: product.dimension.split("\n").map(e => e + " เซนติเมตร"),
      }
      details.push(dimension)
    }

    let spec = {
      name: "ข้อมูลจำเพาะ",
      content: product.specification?.split("\n"),
    }
    details.push(spec)

    setDetails(details)
  }, [product]);

  return (
    <div className="w-full rounded-2xl space-y-2.5">
      {details && details.map((item: any, index: any) => {
        return (
          <Disclosure key={"Disclosure-" + index} defaultOpen={index < 1}>
            {({ open }) => (
              <>
                <Disclosure.Button className="text-xs flex items-center justify-between w-full px-2 py-1 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-md focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
                  <span>{item.name}</span>
                  {!open ? (
                    <PlusIcon className="w-2 h-2 text-slate-600 dark:text-slate-400" />
                  ) : (
                    <MinusIcon className="w-2 h-2 text-slate-600 dark:text-slate-400" />
                  )}
                </Disclosure.Button>
                <Disclosure.Panel
                  className={panelClassName}
                // as="div"
                // dangerouslySetInnerHTML={{ __html: item.content }}
                >
                  <ul className="text-xs list-disc list-inside leading-[0.6rem]">
                    {item.content.map((e: any) => {
                      if (e.length > 0) {
                        return (
                          <li>{e.replace("- ", "").replace("-", "")}</li>
                        )
                      }
                    })}
                  </ul>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        );
      })}

      {/* ============ */}
    </div>
  );
};

export default AccordionInfo;
