import Question0Air from "images/cat-icon-air-conditioner.png";
import Question0Refrigerator from "images/cat-icon-refrigerator.png";
import Question0Television from "images/cat-icon-television.png";
import Question0Washing from "images/cat-icon-washing-machine.png";

import Tv1A from "images/q1-a.png";
import Tv1B from "images/q1-b.png";
import Tv1C from "images/q1-c.png";
import Tv2A from "images/q2-a.png";
import Tv2B from "images/q2-b.png";
import Tv2C from "images/q2-c.png";
import Tv3A from "images/q3-a.png";
import Tv3B from "images/q3-b.png";
import Tv3C from "images/q3-c.png";
import Tv3D from "images/q3-d.png";

import OptionFamilySize1 from "images/option-family-size-1.png";
import OptionFamilySize4 from "images/option-family-size-4.png";
import OptionFamilySize6 from "images/option-family-size-6.png";

import OptionHome from "images/option-home.png";
import OptionTownHouse from "images/option-townhouse.png";
import OptionCondo from "images/option-condo.png";

import OptionWifi from "images/option-wifi.png";
import OptionHot from "images/option-hot.png";
import OptionMulti from "images/option-multi.png";

import OptionCook from "images/option-cook.png";
import OptionPaid from "images/option-paid.png";
import OptionPreserve from "images/option-preserve.png";

import OptionIce from "images/option-ice.png";
import OptionFreeze from "images/option-freeze.png";
import OptionSmartphone from "images/option-smartphone.png";

import OptionBed from "images/option-bed.png";
import OptionLivingRoom from "images/option-living-room.png";
import OptionWork from "images/option-work.png";
import OptionSun from "images/option-sun.png";

import Option12SqM from "images/option-12sqm.png";
import Option16SqM from "images/option-16sqm.png";
import Option20SqM from "images/option-20sqm.png";
import Option28SqM from "images/option-28sqm.png";

import OptionWind from "images/option-wind.png";
import OptionAntiBac from "images/option-anti-bac.png";


export const QUESTION_0_HEAD = "เลือกหมวดหมู่สินค้าที่สนใจ"
export const QUESTION_0 = [
    {
        id: 1,
        ques: "category",
        desc: "ทีวี",
        image: Question0Television,
        color: "bg-indigo-50",
        link: "/question-2",
    },
    {
        id: 2,
        ques: "category",
        desc: "เครื่องซักผ้า",
        image: Question0Washing,
        color: "bg-slate-100/80",
        link: "/question-2",
    },
    {
        id: 3,
        ques: "category",
        desc: "ตู้เย็น",
        image: Question0Refrigerator,
        color: "bg-violet-50",
        link: "/question-2",
    },
    {
        id: 4,
        ques: "category",
        desc: "เครื่องปรับอากาศ",
        image: Question0Air,
        color: "bg-violet-50",
        link: "/question-2",
    }
];

export const QUESTION_TV = {
    "1": {
        "question": "รายการโปรดของท่าน (Lifestyle)",
        "options": [
            {
                id: 1,
                ques: "lifestyle",
                desc: "A. กีฬาและเกมส์",
                image: Tv1A,
                color: "bg-indigo-50",
                link: "/question-2",
            },
            {
                id: 2,
                ques: "lifestyle",
                desc: "B. ดูหนังและซีรีย์",
                image: Tv1B,
                color: "bg-slate-100/80",
                link: "/question-2",
            },
            {
                id: 3,
                ques: "lifestyle",
                desc: "C. ใช้งานทั่วไป",
                image: Tv1C,
                color: "bg-violet-50",
                link: "/question-2",
            }
        ],
    },
    "2": {
        "question": "ขนาดของทีวีที่คุณสนใจ",
        "options": [
            {
                id: 1,
                ques: "size",
                desc: `A. เล็ก (32"-43")`,
                image: Tv2A,
                color: "bg-indigo-50",
                link: "/question-3",
            },
            {
                id: 2,
                ques: "size",
                desc: `B. กลาง (50"-58")`,
                image: Tv2B,
                color: "bg-slate-100/80",
                link: "/question-3",
            },
            {
                id: 3,
                ques: "size",
                desc: `C. ใหญ่ (60"-85")`,
                image: Tv2C,
                color: "bg-violet-50",
                link: "/question-3",
            }
        ],
    },
    "3": {
        "question": "ฟังก์ชั่นพิเศษของจอทีวี",
        "options": [
            {
                id: 1,
                ques: "feature",
                desc: `A. ระบบ Android `,
                image: Tv3B,
                color: "bg-slate-100/80",
                link: "/product-list/ทีวี/0",
            },
            {
                id: 2,
                ques: "feature",
                desc: `B. สั่งการด้วยเสียง`,
                image: Tv3A,
                color: "bg-indigo-50",
                link: "/product-list/ทีวี/0",
            },
            {
                id: 3,
                ques: "feature",
                desc: `C. ความชัด 8K`,
                image: Tv3C,
                color: "bg-violet-50",
                link: "/product-list/ทีวี/0",
            },
            // {
            //     id: 4,
            //     ques: "feature",
            //     desc: `D. Design`,
            //     image: Tv3D,
            //     color: "bg-violet-50",
            //     link: "/product-list/ทีวี/0",
            // }
        ],
    }
}

export const QUESTION_WASHING_MACHINE = {
    "1": {
        "question": "ขนาดของครอบครัว",
        "options": [
            {
                id: 1,
                ques: "lifestyle",
                desc: "A. ครอบครัว 6 คนขึ้นไป",
                image: OptionFamilySize6,
                color: "bg-indigo-50",
                link: "/question-2",
            },
            {
                id: 2,
                ques: "lifestyle",
                desc: "B. ครอบครัว 4-6 คนขึ้นไป",
                image: OptionFamilySize4,
                color: "bg-slate-100/80",
                link: "/question-2",
            },
            {
                id: 3,
                ques: "lifestyle",
                desc: "C. ครอบครัว 1-3 คนขึ้นไป",
                image: OptionFamilySize1,
                color: "bg-violet-50",
                link: "/question-2",
            }
        ],
    },
    "2": {
        "question": "ประเภทของที่พักอาศัย",
        "options": [
            {
                id: 1,
                ques: "lifestyle",
                desc: "A. บ้านเดี่ยว",
                image: OptionHome,
                color: "bg-indigo-50",
                link: "/question-2",
            },
            {
                id: 2,
                ques: "lifestyle",
                desc: "B. ทาวเฮ้า",
                image: OptionTownHouse,
                color: "bg-slate-100/80",
                link: "/question-2",
            },
            {
                id: 3,
                ques: "lifestyle",
                desc: "C. คอนโด",
                image: OptionCondo,
                color: "bg-violet-50",
                link: "/question-2",
            }
        ],
    },
    "3": {
        "question": "รูปแบบการใช้งานของเครื่องซักผ้า",
        "options": [
            {
                id: 1,
                ques: "lifestyle",
                desc: "A. Smart Wifi Command",
                image: OptionWifi,
                color: "bg-indigo-50",
                link: "/question-2",
            },
            {
                id: 2,
                ques: "lifestyle",
                desc: "B. Hygiene (ซักน้ำร้อน)",
                image: OptionHot,
                color: "bg-slate-100/80",
                link: "/question-2",
            },
            {
                id: 3,
                ques: "lifestyle",
                desc: "C. All in one",
                image: OptionMulti,
                color: "bg-violet-50",
                link: "/question-2",
            }
        ],
    },
}

export const QUESTION_AIR = {
    "1": {
        "question": "ห้องที่ต้องการติดตั้ง",
        "options": [
            {
                id: 1,
                ques: "lifestyle",
                desc: "A. ห้องนอน",
                image: OptionBed,
                color: "bg-indigo-50",
                link: "/question-2",
            },
            {
                id: 2,
                ques: "lifestyle",
                desc: "B. ห้องนั่งเล่น",
                image: OptionLivingRoom,
                color: "bg-slate-100/80",
                link: "/question-2",
            },
            {
                id: 3,
                ques: "lifestyle",
                desc: "C. ห้องทำงาน",
                image: OptionWork,
                color: "bg-violet-50",
                link: "/question-2",
            },
            {
                id: 4,
                ques: "lifestyle",
                desc: "D. ห้องที่โดนแสงแดด",
                image: OptionSun,
                color: "bg-violet-50",
                link: "/question-2",
            }
        ],
    },
    "2": {
        "question": "พื้นที่ห้อง",
        "options": [
            {
                id: 1,
                ques: "lifestyle",
                desc: "A. 12-15 ตร.ม.",
                image: Option12SqM,
                color: "bg-indigo-50",
                link: "/question-2",
            },
            {
                id: 2,
                ques: "lifestyle",
                desc: "B. 16-20 ตร.ม.",
                image: Option16SqM,
                color: "bg-slate-100/80",
                link: "/question-2",
            },
            {
                id: 3,
                ques: "lifestyle",
                desc: "C. 20-28 ตร.ม.",
                image: Option20SqM,
                color: "bg-violet-50",
                link: "/question-2",
            },
            {
                id: 4,
                ques: "lifestyle",
                desc: "D. 28-35 ตร.ม.",
                image: Option28SqM,
                color: "bg-violet-50",
                link: "/question-2",
            }
        ],
    },
    "3": {
        "question": "ฟังก์ชั่นของแอร์",
        "options": [
            {
                id: 1,
                ques: "lifestyle",
                desc: "A. เย็นสบาย ลมไม่ปะทะตัว",
                image: OptionWind,
                color: "bg-indigo-50",
                link: "/question-2",
            },
            {
                id: 2,
                ques: "lifestyle",
                desc: "B. ฆ่าเชื้อโรค",
                image: OptionAntiBac,
                color: "bg-slate-100/80",
                link: "/question-2",
            },
            {
                id: 3,
                ques: "lifestyle",
                desc: "C. สั่งการผ่าน Application",
                image: OptionSmartphone,
                color: "bg-violet-50",
                link: "/question-2",
            }
        ],
    },
}

export const QUESTION_REFRIGERATOR = {
    "1": {
        "question": "ขนาดของครอบครัว",
        "options": [
            {
                id: 1,
                ques: "lifestyle",
                desc: "A. ครอบครัว 6 คนขึ้นไป",
                image: OptionFamilySize6,
                color: "bg-indigo-50",
                link: "/question-2",
            },
            {
                id: 2,
                ques: "lifestyle",
                desc: "B. ครอบครัว 4-6 คนขึ้นไป",
                image: OptionFamilySize4,
                color: "bg-slate-100/80",
                link: "/question-2",
            },
            {
                id: 3,
                ques: "lifestyle",
                desc: "C. ครอบครัว 1-3 คนขึ้นไป",
                image: OptionFamilySize1,
                color: "bg-violet-50",
                link: "/question-2",
            }
        ],
    },
    "2": {
        "question": "ประเภทของที่พักอาศัย",
        "options": [
            {
                id: 1,
                ques: "lifestyle",
                desc: "A. บ้านเดี่ยว",
                image: OptionHome,
                color: "bg-indigo-50",
                link: "/question-2",
            },
            {
                id: 2,
                ques: "lifestyle",
                desc: "B. ทาวเฮ้า",
                image: OptionTownHouse,
                color: "bg-slate-100/80",
                link: "/question-2",
            },
            {
                id: 3,
                ques: "lifestyle",
                desc: "C. คอนโด",
                image: OptionCondo,
                color: "bg-violet-50",
                link: "/question-2",
            }
        ],
    },
    "3": {
        "question": "Special Function",
        "options": [
            {
                id: 1,
                ques: "lifestyle",
                desc: "A. ทำน้ำแข็งอัตโนมัติ",
                image: OptionIce,
                color: "bg-indigo-50",
                link: "/question-2",
            },
            {
                id: 2,
                ques: "lifestyle",
                desc: "B. รักษาความสดของอาหาร",
                image: OptionFreeze,
                color: "bg-slate-100/80",
                link: "/question-2",
            },
            {
                id: 3,
                ques: "lifestyle",
                desc: "C. เชื่อมต่อผ่านสมาร์ทโฟน",
                image: OptionSmartphone,
                color: "bg-violet-50",
                link: "/question-2",
            }
        ],
    },
}