import React, { FC, useEffect, useState } from "react";

import CardCategory6 from "components/CardCategories/CardCategory6";
import NcImage from "shared/NcImage/NcImage";
import QuestionBg from "../images/question-bg.png";
import { useHistory } from "react-router-dom";
import useQuery from "hooks/useQuery";

interface QuestionType {
  id: number;
  ques: string;
  desc: string;
  image: string;
  color?: string;
  link?: string;
}

export interface QuestionareProps {
  className?: string;
  gridClassName?: string;
  boxCard?: "box1" | "box4" | "box6";
  data: QuestionType[];
  questionHead: string;
}

const Questionare: FC<QuestionareProps> = ({
  className = "",
  boxCard = "box6",
  gridClassName = "grid-cols-3", // grid-cols-1 md:grid-cols-2 xl:
  data,
  questionHead,
}) => {
  const history = useHistory();
  const query = useQuery();
  const [tabActive, setTabActive] = React.useState("Man");
  const [backPick, setBackPick] = useState("");

  const renderCard = (item: QuestionType) => {
    switch (boxCard) {
      case "box6":
        return (
          <CardCategory6
            name=""
            desc={item.desc}
            featuredImage={item.image}
            key={item.id}
            id={item.id}
            color={item.color}
            link={item.link}
            ques={item.ques}
          />
        );

      default:
        return <></>;
    }
  };

  const renderBackIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 36 36" aria-hidden="true" role="img" preserveAspectRatio="xMidYMid meet"><path fill="#31373D" d="M34 3H12.475V1.128c0-1.046-.74-1.435-1.645-.865L.69 6.652c-.905.57-.922 1.527-.038 2.127l10.215 6.931c.884.602 1.607.235 1.607-.811V13H34a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zM.024 26.184c0-.727.5-1.137 1.197-1.137H4.13c1.576 0 2.849 1.061 2.849 2.667c0 1.061-.439 1.772-1.409 2.227v.03c1.288.183 2.303 1.258 2.303 2.576c0 2.137-1.424 3.288-3.516 3.288h-3.12c-.697 0-1.212-.439-1.212-1.151v-8.5zm2.273 3.135h1.182c.742 0 1.227-.439 1.227-1.196c0-.713-.561-1.076-1.227-1.076H2.297v2.272zm0 4.516h1.788c.818 0 1.424-.47 1.424-1.318c0-.712-.545-1.197-1.606-1.197H2.297v2.515zm9.217-7.713c.258-.696.85-1.257 1.621-1.257c.805 0 1.365.53 1.621 1.257l2.971 8.243c.092.242.121.454.121.561c0 .591-.484 1-1.045 1c-.637 0-.955-.333-1.107-.788l-.453-1.424H11.03l-.455 1.409c-.15.47-.469.803-1.09.803c-.607 0-1.122-.454-1.122-1.061c0-.242.076-.424.106-.5l3.045-8.243zm.168 5.501h2.879l-1.41-4.395h-.029l-1.44 4.395zm11.378-6.758c1.106 0 3.258.363 3.258 1.696c0 .546-.379 1.016-.94 1.016c-.621 0-1.046-.53-2.318-.53c-1.879 0-2.849 1.591-2.849 3.439c0 1.803.985 3.349 2.849 3.349c1.272 0 1.788-.637 2.409-.637c.682 0 1 .682 1 1.03c0 1.455-2.288 1.788-3.409 1.788c-3.076 0-5.212-2.439-5.212-5.576c0-3.151 2.121-5.575 5.212-5.575zm4.471 1.212c0-.621.455-1.121 1.137-1.121c.651 0 1.137.424 1.137 1.121v3.273l3.727-3.97c.167-.182.455-.424.879-.424c.576 0 1.121.439 1.121 1.091c0 .393-.242.712-.742 1.212l-2.863 2.818l3.5 3.651c.363.364.637.697.637 1.152c0 .712-.562 1.045-1.183 1.045c-.44 0-.727-.258-1.151-.712l-3.924-4.243v3.864c0 .591-.455 1.091-1.137 1.091c-.651 0-1.137-.424-1.137-1.091v-8.757z" /></svg>
    );
  };

  const onBackButton = () => {
    if (backPick !== "") {
      history.push(`/?pick=${backPick}`);
    } else {
      window.location.replace("/");
    }
  }

  useEffect(() => {
    let pick = query.get("pick")
    if (!pick) return;
    // console.log("pick", pick);

    let pickOptions = pick.split("_")[1];
    if (pickOptions.length <= 0) {
      setBackPick("")
      return;
    }
    // console.log("pickOptions", pickOptions)

    let back_pick = pick.slice(0, pick.length - 1)
    // console.log("back_pick", back_pick)
    setBackPick(back_pick)

  }, [query.get("pick")])

  return (
    <div
      className={`my-0 pb-5 nc-Questionare relative ${className}`}
      data-nc-id="Questionare"
    >
      {(!((window.location.pathname.indexOf("/") + 1) % (window.location.pathname.lastIndexOf("/") + 1) === 0) ||
        window.location.pathname.includes("/product-navigation") ||
        window.location.toString().includes("?")) &&
        <div
          className="absolute mt-8 z-50"
          onClick={onBackButton}
        >
          {renderBackIcon()}
        </div>
      }
      <div className="w-full pt-4 pb-2">
        <div className="relative">
          {/* <img src={QuestionBg} /> */}
          <NcImage
            src={QuestionBg}
            containerClassName={`w-auto h-12 overflow-hidden z-0`}
          />
          <h1 className="absolute font-medium text-md top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            {questionHead}</h1>
        </div>
      </div>
      <div className={`grid gap-2 grid-cols-${data.length}`}>
        {data.map((item) => renderCard(item))}
      </div>
    </div>
  );
};

export default Questionare;
