import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { Helmet } from "react-helmet";
import SectionPromo2 from "components/SectionPromo2";
import Questionare from "components/Questionare";
import { useEffect, useState } from "react";

import useChatbot from "../../chatbot/Chatbot";

import { QUESTION_0, QUESTION_0_HEAD } from "data/questions";
import useQuery from "hooks/useQuery";
import { CATEGORIES } from "data/data";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "hooks/useWindowDimensions";

function PageHome2() {
  const history = useHistory();
  const query = useQuery();
  const chatbot = useChatbot();
  const { height, width } = useWindowDimensions();

  const [questionArray, setQuestionArray] = useState(QUESTION_0);
  const [questionHead, setQuestionHead] = useState(QUESTION_0_HEAD);

  useEffect(() => {
    // chatbot.enterImmerseMode(true)
  }, [])

  useEffect(() => {
    let pick = query.get("pick")
    if (!pick) return;

    // console.log("pick", pick);
    let pickCategory = CATEGORIES.find(e => pick!.includes(e.name))
    if (!pickCategory) return;
    // console.log("pickCategory", pickCategory)

    let pickOptions = pick.split("_")[1];
    // console.log("pickOptions", pickOptions)
    if (pickOptions) {
      if (pickOptions.length >= 3) {
        history.push(`/product-list/${pickCategory.name}/${pickOptions}`);
      } else {
        setQuestionHead(pickCategory.question[`${pickOptions.length + 1}`]["question"])
        setQuestionArray(pickCategory.question[`${pickOptions.length + 1}`]["options"])

        let lastPickOptionFromUrl = parseInt(pickOptions.slice(-1))
        let options = pickCategory.question[`${pickOptions.length}`]["options"]
        let lastPickOption = options[lastPickOptionFromUrl - 1]
        let lastPickOptionDesc = lastPickOption["desc"].split(". ").pop()
        // console.log("lastPickOptionDesc", lastPickOptionDesc) // .slice(". ").pop()
        switch (pickCategory.name) {
          case "ทีวี":
            chatbot.textTriggerNlp(lastPickOptionDesc)
            break;
          case "เครื่องซักผ้า":
            chatbot.textTriggerNlp('เครื่องซักผ้าสำหรับ' + lastPickOptionDesc)
            break;
          case "ตู้เย็น":
            chatbot.textTriggerNlp('ตู้เย็นสำหรับ' + lastPickOptionDesc)
            break;
          case "เครื่องปรับอากาศ":
            chatbot.textTriggerNlp(lastPickOptionDesc)
            break;
          default:
            break;
        }
      }
    } else {
      switch (pickCategory.name) {
        case "ทีวี":
          chatbot.textTriggerNlp('Introduce TV')
          break;
        case "เครื่องซักผ้า":
          chatbot.textTriggerNlp('Introduce Washing Machine')
          break;
        case "ตู้เย็น":
          chatbot.textTriggerNlp('Introduce Refrigerator')
          break;
        case "เครื่องปรับอากาศ":
          chatbot.textTriggerNlp('Introduce Air Conditioner')
          break;
        default:
          break;
      }
      setQuestionHead(pickCategory.question["1"]["question"])
      setQuestionArray(pickCategory.question["1"]["options"])
    }

  }, [query.get("pick")])

  return (
    <div className="nc-PageHome2 relative overflow-hidden">
      <Helmet>
        <title>Lotus E-catalog</title>
      </Helmet>

      {/* <div className="container px-4">
        <SectionHero3 />
      </div> */}

      <div className="container relative space-y-24 mb-0 lg:space-y-32 lg:mb-32" id="homepage-container">
        {/* <div className="text-3xl mt-4">
          width: {width} ~ height: {height}
        </div> */}
        {/* SECTION */}
        <div className="pb-4 relative lg:py-16">
          <BackgroundSection />
          <Questionare
            questionHead={questionHead}
            data={questionArray}
            gridClassName="grid-cols-4"
          />
        </div>

        {/* SECTION */}
        {/* <SectionPromo2 /> */}

        {/* SECTION 3 */}
        {/* <SectionSliderLargeProduct /> */}

        {/* SECTION */}
        {/* <SectionPromo1 /> */}

        {/* <SectionHowItWork /> */}

        {/* SECTION */}
        {/* <SectionSliderProductCard
          data={SPORT_PRODUCTS.filter((_, i) => i < 8)}
          subHeading="New Sports equipment"
        /> */}

        {/* SECTION */}
        {/* <SectionGridFeatureItems data={SPORT_PRODUCTS} /> */}
      </div>
    </div>
  );
}

export default PageHome2;
