import React, { FC } from "react";

export interface PricesProps {
  className?: string;
  price?: string;
  contentClass?: string;
}

const Prices: FC<PricesProps> = ({
  className = "",
  price = 33,
  contentClass = "py-1 px-1 text-xs font-medium",
}) => {
  return (
    <div className={`${className}`}>
      <div
        className={`flex items-center border-2 border-green-500 rounded-md ${contentClass}`}
      >
        <span className="text-green-500 !leading-none">
          {price} บาท
        </span>
      </div>
    </div>
  );
};

export default Prices;
