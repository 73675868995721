import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import ProductCard from "components/ProductCard";
import { CATEGORIES, ProductCategories, Product } from "data/data";
import rawProducts from "data/products_format.json";
import productMedia from "data/s3Media.json";
import recommendData from "data/recommendation.json";
import useChatbot from "chatbot/Chatbot";

type TRecommend = "1" | "2" | "3";

export interface PageCollectionProps {
  className?: string;
}

function shuffle(array: any) {
  return array.sort(() => Math.random() - 0.5);
}

const PageCollection: FC<PageCollectionProps> = ({ className = "" }) => {
  const chatbot = useChatbot();

  const { productType } = useParams<{ productType?: string }>();
  const { recommendId } = useParams<{ recommendId?: string }>();

  const [category, setCategory] = useState<ProductCategories | undefined>(undefined);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    // console.log(productType, recommendId);

    let result = CATEGORIES.find(e => e.name === productType);
    if (result && recommendId) {
      // if (result.name === "ทีวี") chatbot.textTriggerNlp('Introduce TV')
      setCategory(result);

      let filter_products = rawProducts.filter(e => e.category === productType);
      // console.log("filter_products", filter_products)
      let objProducts: Product[] = filter_products.map(e => {
        // let tv = [
        //   "https://backend.central.co.th/media/catalog/product/e/e/eefdc758696b167815db5c9a0b317614ea49dfe6_mkp1164057dummy.jpg?impolicy=resize&width=553",
        //   "https://backend.central.co.th/media/catalog/product/3/5/3546ff40143125758f64b485e66dd959a3d52940_267645dummy.jpg?impolicy=resize&width=553",
        //   "https://backend.central.co.th/media/catalog/product/c/1/c1a9e3b7f6a97b63ca93ea707723e1d9d0d2ea77_265458dummy.jpg?impolicy=resize&width=553",
        //   "https://backend.central.co.th/media/catalog/product/2/5/25f7e23b7acc76a14c47a393edbf1250015f0b39_277052dummy.jpg?impolicy=resize&width=553",
        //   "https://backend.central.co.th/media/catalog/product/1/d/1db1511532ae0d91042677e1b1e6df0fbd68ee93_270421dummy.jpg?impolicy=resize&width=553",
        //   "https://backend.central.co.th/media/catalog/product/c/a/ca728081c2995f7e0a46930ce7638d5841fe552c_278218dummy.jpg?impolicy=resize&width=553",
        //   "https://backend.central.co.th/media/catalog/product/3/7/37b721c23d5b74d1a35a1f6e2dc77a6ee35d5775_282275.jpegdummy.jpg?impolicy=resize&width=553",
        // ]
        // let washing = [
        //   "https://backend.central.co.th/media/catalog/product/1/6/16585009b69b29164bff11edc290550cc57e8b52_235323dummy.jpg?impolicy=resize&width=400",
        //   "https://backend.central.co.th/media/catalog/product/8/e/8ed55506b97f06ec955b05f4e659d93fefd93e72_249256dummy.jpg?impolicy=resize&width=400",
        //   "https://backend.central.co.th/media/catalog/product/0/2/0226b3819948d892a9888c5a1b50a962e0d70275_266722dummy.jpg?impolicy=resize&width=400",
        //   "https://backend.central.co.th/media/catalog/product/c/f/cf77632335ce026327cdf23e0f47012b8d0b14e0_279725dummy.jpg?impolicy=resize&width=400",
        //   "https://backend.central.co.th/media/catalog/product/2/f/2f9e02790bc5c59c7031a7f6cf3a408bf295555f_276311dummy.jpg?impolicy=resize&width=400",
        //   "https://backend.central.co.th/media/catalog/product/3/6/36d6af7dc3d8adf6acbf0d7ba0c2711cd97d7a36_266726dummy.jpg?impolicy=resize&width=553",
        // ]
        // let refrid = [
        //   "https://backend.central.co.th/media/catalog/product/7/f/7f13cd1a2330375de46f0a1a14da08af12e66843_243223dummy.jpg?impolicy=resize&width=400",
        //   "https://backend.central.co.th/media/catalog/product/6/e/6eefa09aa39e1578316e50f6305a5b3d35650887_251030dummy.jpg?impolicy=resize&width=553",
        //   "https://backend.central.co.th/media/catalog/product/9/e/9ed7be5029324cad73664a012dea50a7ec4d295a_252729dummy.jpg?impolicy=resize&width=400",
        //   "https://backend.central.co.th/media/catalog/product/1/6/1667ba8cb5773f5903af5c51348a155f9690c4d0_234345dummy.jpg?impolicy=resize&width=400",
        // ]
        // let air = [
        //   "https://backend.central.co.th/media/catalog/product/e/2/e2be92cace18d545be4efcd581ad33de8d34ad46_mkp1192449dummy.jpg?impolicy=resize&width=400",
        //   "https://backend.central.co.th/media/catalog/product/a/d/adb75398cfd5fb87aa420d2eeeae9b56459bf2fb_mkp1261770dummy.jpg?impolicy=resize&width=553",
        //   "https://backend.central.co.th/media/catalog/product/2/2/22daa215761c45b76ee3f40f6f143be020241481_277863dummy.jpg?impolicy=resize&width=400",
        //   "https://backend.central.co.th/media/catalog/product/2/8/2844de05b62591669e2e04a3fffc2fe89c756dc2_278871dummy.jpg?impolicy=resize&width=400",
        // ]

        let images: string[] = [];

        let findImages = productMedia.filter(media => (
          media.includes(e.id.toString()) && !media.includes(".mp4")
        ))
        findImages = findImages.map(e => e.replace("#", "%23"))
        // console.log("findImages", findImages)
        if (findImages.length > 0) {
          images = findImages
        } else {
          // console.log("No image with product TPNA", e.id)
          // if (productType === "เครื่องซักผ้า") {
          //   images = [
          //     washing[Math.floor(Math.random() * washing.length)],
          //     "https://backend.central.co.th/media/catalog/product/6/7/672c109cf31abbed041d46265a157eca364f4294_mkp1164057dummy.jpg?impolicy=resize&width=553",
          //     "https://backend.central.co.th/media/catalog/product/5/5/5522abf640b97d6a1a828c7d341f112b7042063b_mkp1164057dummy.jpg?impolicy=resize&width=553"
          //   ]
          // } else if (productType === "ทีวี") {
          //   images = [
          //     tv[Math.floor(Math.random() * tv.length)],
          //     "https://backend.central.co.th/media/catalog/product/6/7/672c109cf31abbed041d46265a157eca364f4294_mkp1164057dummy.jpg?impolicy=resize&width=553",
          //     "https://backend.central.co.th/media/catalog/product/5/5/5522abf640b97d6a1a828c7d341f112b7042063b_mkp1164057dummy.jpg?impolicy=resize&width=553"
          //   ]
          // } else if (productType === "ตู้เย็น") {
          //   images = [
          //     refrid[Math.floor(Math.random() * refrid.length)],
          //     "https://backend.central.co.th/media/catalog/product/6/7/672c109cf31abbed041d46265a157eca364f4294_mkp1164057dummy.jpg?impolicy=resize&width=553",
          //     "https://backend.central.co.th/media/catalog/product/5/5/5522abf640b97d6a1a828c7d341f112b7042063b_mkp1164057dummy.jpg?impolicy=resize&width=553"
          //   ]
          // } else if (productType === "เครื่องปรับอากาศ") {
          //   images = [
          //     air[Math.floor(Math.random() * air.length)],
          //     "https://backend.central.co.th/media/catalog/product/6/7/672c109cf31abbed041d46265a157eca364f4294_mkp1164057dummy.jpg?impolicy=resize&width=553",
          //     "https://backend.central.co.th/media/catalog/product/5/5/5522abf640b97d6a1a828c7d341f112b7042063b_mkp1164057dummy.jpg?impolicy=resize&width=553"
          //   ]
          // }
        }

        let productObj: Product = {
          id: e.id.toString(),
          category: e.category,
          sub_category: e.sub_category,
          name: e.name,
          brand: e.brand,
          dimension: e.dimension,
          description: e.description,
          specification: e.specification,
          price: e.price ? parseInt(e.price.toString().replace(/[^0-9.-]+/g, "")) : 0.00,
          image: images,
        }
        return productObj
      });

      // recommendation
      if (recommendId !== "0" && recommendId.length >= 3) {
        let recArr = recommendId.split("");
        if (productType === "เครื่องซักผ้า") {
          chatbot.textTriggerNlp("Smart wifi command")
          const idsByRecommend = recommendData["WashingMachine"][recArr[0] as TRecommend][recArr[1] as TRecommend][recArr[2] as TRecommend]
          objProducts = objProducts.filter(e => idsByRecommend.includes(e.id));
        } else if (productType === "ทีวี") {
          chatbot.textTriggerNlp("สั่งการด้วยเสียง​")
          const idsByRecommend = recommendData["TV"][recArr[0] as TRecommend][recArr[1] as TRecommend][recArr[2] as TRecommend]
          objProducts = objProducts.filter(e => idsByRecommend.includes(e.id));
        } else if (productType === "ตู้เย็น") {
          chatbot.textTriggerNlp("Ice Maker")
          const idsByRecommend = recommendData["Refrigerator"][recArr[0] as TRecommend][recArr[1] as TRecommend][recArr[2] as TRecommend]
          objProducts = objProducts.filter(e => idsByRecommend.includes(e.id));
        } else if (productType === "เครื่องปรับอากาศ") {
          chatbot.textTriggerNlp("ฆ่าเชื้อโรค")
          // const idsByRecommend = recommendData["Air"][recArr[0] as TRecommend][recArr[1] as TRecommend][recArr[2] as TRecommend]
          // objProducts = objProducts.filter(e => idsByRecommend.includes(e.id));
        }
      }

      // show product that have images first
      let sortProducts: Product[] = []
      objProducts.forEach(e => {
        if (e.image.length > 0) { // && e.image.includes("graffity-public-assets")
          sortProducts.unshift(e)
        } else {
          sortProducts.push(e)
        }
      })

      // cut to only 6 products
      if (recommendId !== "0") {
        sortProducts = sortProducts.slice(0, 6)
        // if (productType !== "ทีวี") {
        //   sortProducts = shuffle(sortProducts)
        // }
      }

      console.log("Total", productType, sortProducts.length)
      setProducts(sortProducts);
    }
  }, [productType, recommendId]);

  return (
    <div
      className={`nc-PageCollection ${className}`}
      data-nc-id="PageCollection"
    >
      <Helmet>
        <title>Collection || Lotus E-catalog Ecommerce</title>
      </Helmet>

      {category && products && productType && recommendId &&
        <div className="container py-6 space-y-16 sm:space-y-20 lg:space-y-28">
          <div className="space-y-10 lg:space-y-14">
            {/* HEADING */}
            <div className="max-w-screen-sm">
              <h2 className="block text-md sm:text-3xl lg:text-4xl font-semibold">
                {recommendId !== "0" ? category.name + "แนะนำ" : category.name + "ทั้งหมด"}
              </h2>
              <span className="block mt-0 text-neutral-500 dark:text-neutral-400 text-xs sm:text-base">
                {category.long_description}
              </span>
            </div>

            {/* <hr className="border-slate-200 dark:border-slate-700" /> */}
            <div className="mt-2">
              {/* TABS FILTER */}
              {/* <TabFilters /> */}

              {/* LOOP ITEMS */}
              <div className="grid grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-6 mt-0">
                {products.map((item, index) => (
                  <ProductCard data={item} key={index} />
                ))}
              </div>

              {/* PAGINATION */}
              {/* <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                <Pagination />
                <ButtonPrimary loading>Show me more</ButtonPrimary>
              </div> */}
            </div>
          </div>

          {/* === SECTION 5 === */}
          {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

          {/* <SectionSliderCollections /> */}
          {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

          {/* SUBCRIBES */}
          {/* <SectionPromo1 /> */}
        </div>
      }


    </div>
  );
};

export default PageCollection;

// TV search by size
          // let inchMin = 0
          // let inchMax = 0
          // switch (recommendId.slice(1, 2)) {
          //   case "1":
          //     inchMin = 32
          //     inchMax = 43
          //     break;
          //   case "2":
          //     inchMin = 50
          //     inchMax = 58
          //     break;
          //   case "3":
          //     inchMin = 60
          //     inchMax = 85
          //     break;
          // }
          // objProducts = objProducts.filter(e => {
          //   let tvSize = 0
          //   let tvName = e.name.replace(/\s/g, '').toLocaleLowerCase()
          //   if (tvName.includes(`"`)) {
          //     let idx = tvName.indexOf(`"`)
          //     tvSize = parseInt(tvName.slice(idx - 2, idx))
          //   } else if (tvName.includes(`in`)) {
          //     let idx = tvName.indexOf(`in`)
          //     tvSize = parseInt(tvName.slice(idx - 2, idx))
          //   }
          //   // console.log("tvSize inch", tvSize)
          //   if (inchMin < tvSize && tvSize < inchMax) return e;
          // })