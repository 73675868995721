import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import AccordionInfo from "./AccordionInfo";
import BagIcon from "components/BagIcon";
import { Product, PRODUCTS } from "data/data";
import Prices from "components/Prices";
import { Link, useParams, useHistory } from "react-router-dom";
import rawProducts from "data/products_format.json";
import productMedia from "data/s3Media.json";
import placeHolderImage from "images/placeholder-image.png";

export interface ProductDetailPageProps {
  className?: string;
}

let tv = [
  "https://backend.central.co.th/media/catalog/product/e/e/eefdc758696b167815db5c9a0b317614ea49dfe6_mkp1164057dummy.jpg?impolicy=resize&width=553",
  "https://backend.central.co.th/media/catalog/product/3/5/3546ff40143125758f64b485e66dd959a3d52940_267645dummy.jpg?impolicy=resize&width=553",
  "https://backend.central.co.th/media/catalog/product/c/1/c1a9e3b7f6a97b63ca93ea707723e1d9d0d2ea77_265458dummy.jpg?impolicy=resize&width=553",
  "https://backend.central.co.th/media/catalog/product/2/5/25f7e23b7acc76a14c47a393edbf1250015f0b39_277052dummy.jpg?impolicy=resize&width=553",
  "https://backend.central.co.th/media/catalog/product/1/d/1db1511532ae0d91042677e1b1e6df0fbd68ee93_270421dummy.jpg?impolicy=resize&width=553",
  "https://backend.central.co.th/media/catalog/product/c/a/ca728081c2995f7e0a46930ce7638d5841fe552c_278218dummy.jpg?impolicy=resize&width=553",
  "https://backend.central.co.th/media/catalog/product/3/7/37b721c23d5b74d1a35a1f6e2dc77a6ee35d5775_282275.jpegdummy.jpg?impolicy=resize&width=553",
]
let washing = [
  "https://backend.central.co.th/media/catalog/product/1/6/16585009b69b29164bff11edc290550cc57e8b52_235323dummy.jpg?impolicy=resize&width=400",
  "https://backend.central.co.th/media/catalog/product/8/e/8ed55506b97f06ec955b05f4e659d93fefd93e72_249256dummy.jpg?impolicy=resize&width=400",
  "https://backend.central.co.th/media/catalog/product/0/2/0226b3819948d892a9888c5a1b50a962e0d70275_266722dummy.jpg?impolicy=resize&width=400",
  "https://backend.central.co.th/media/catalog/product/c/f/cf77632335ce026327cdf23e0f47012b8d0b14e0_279725dummy.jpg?impolicy=resize&width=400",
  "https://backend.central.co.th/media/catalog/product/2/f/2f9e02790bc5c59c7031a7f6cf3a408bf295555f_276311dummy.jpg?impolicy=resize&width=400",
  "https://backend.central.co.th/media/catalog/product/3/6/36d6af7dc3d8adf6acbf0d7ba0c2711cd97d7a36_266726dummy.jpg?impolicy=resize&width=553",
]
let refrid = [
  "https://backend.central.co.th/media/catalog/product/7/f/7f13cd1a2330375de46f0a1a14da08af12e66843_243223dummy.jpg?impolicy=resize&width=400",
  "https://backend.central.co.th/media/catalog/product/6/e/6eefa09aa39e1578316e50f6305a5b3d35650887_251030dummy.jpg?impolicy=resize&width=553",
  "https://backend.central.co.th/media/catalog/product/9/e/9ed7be5029324cad73664a012dea50a7ec4d295a_252729dummy.jpg?impolicy=resize&width=400",
  "https://backend.central.co.th/media/catalog/product/1/6/1667ba8cb5773f5903af5c51348a155f9690c4d0_234345dummy.jpg?impolicy=resize&width=400",
]
let air = [
  "https://backend.central.co.th/media/catalog/product/e/2/e2be92cace18d545be4efcd581ad33de8d34ad46_mkp1192449dummy.jpg?impolicy=resize&width=400",
  "https://backend.central.co.th/media/catalog/product/a/d/adb75398cfd5fb87aa420d2eeeae9b56459bf2fb_mkp1261770dummy.jpg?impolicy=resize&width=553",
  "https://backend.central.co.th/media/catalog/product/2/2/22daa215761c45b76ee3f40f6f143be020241481_277863dummy.jpg?impolicy=resize&width=400",
  "https://backend.central.co.th/media/catalog/product/2/8/2844de05b62591669e2e04a3fffc2fe89c756dc2_278871dummy.jpg?impolicy=resize&width=400",
]

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
  const { id } = useParams<{ id?: string }>()
  const history = useHistory();

  const [product, setProduct] = useState<Product | undefined>(undefined);

  const renderBackIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 36 36" aria-hidden="true" role="img" preserveAspectRatio="xMidYMid meet"><path fill="#31373D" d="M34 3H12.475V1.128c0-1.046-.74-1.435-1.645-.865L.69 6.652c-.905.57-.922 1.527-.038 2.127l10.215 6.931c.884.602 1.607.235 1.607-.811V13H34a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zM.024 26.184c0-.727.5-1.137 1.197-1.137H4.13c1.576 0 2.849 1.061 2.849 2.667c0 1.061-.439 1.772-1.409 2.227v.03c1.288.183 2.303 1.258 2.303 2.576c0 2.137-1.424 3.288-3.516 3.288h-3.12c-.697 0-1.212-.439-1.212-1.151v-8.5zm2.273 3.135h1.182c.742 0 1.227-.439 1.227-1.196c0-.713-.561-1.076-1.227-1.076H2.297v2.272zm0 4.516h1.788c.818 0 1.424-.47 1.424-1.318c0-.712-.545-1.197-1.606-1.197H2.297v2.515zm9.217-7.713c.258-.696.85-1.257 1.621-1.257c.805 0 1.365.53 1.621 1.257l2.971 8.243c.092.242.121.454.121.561c0 .591-.484 1-1.045 1c-.637 0-.955-.333-1.107-.788l-.453-1.424H11.03l-.455 1.409c-.15.47-.469.803-1.09.803c-.607 0-1.122-.454-1.122-1.061c0-.242.076-.424.106-.5l3.045-8.243zm.168 5.501h2.879l-1.41-4.395h-.029l-1.44 4.395zm11.378-6.758c1.106 0 3.258.363 3.258 1.696c0 .546-.379 1.016-.94 1.016c-.621 0-1.046-.53-2.318-.53c-1.879 0-2.849 1.591-2.849 3.439c0 1.803.985 3.349 2.849 3.349c1.272 0 1.788-.637 2.409-.637c.682 0 1 .682 1 1.03c0 1.455-2.288 1.788-3.409 1.788c-3.076 0-5.212-2.439-5.212-5.576c0-3.151 2.121-5.575 5.212-5.575zm4.471 1.212c0-.621.455-1.121 1.137-1.121c.651 0 1.137.424 1.137 1.121v3.273l3.727-3.97c.167-.182.455-.424.879-.424c.576 0 1.121.439 1.121 1.091c0 .393-.242.712-.742 1.212l-2.863 2.818l3.5 3.651c.363.364.637.697.637 1.152c0 .712-.562 1.045-1.183 1.045c-.44 0-.727-.258-1.151-.712l-3.924-4.243v3.864c0 .591-.455 1.091-1.137 1.091c-.651 0-1.137-.424-1.137-1.091v-8.757z" /></svg>
    );
  };

  const renderSectionContent = () => {
    if (product) {
      return (
        <div className="space-y-7 2xl:space-y-8">
          {/* ---------- 1 HEADING ----------  */}
          <div>
            <h2 className="text-sm font-semibold">
              {product.name} {product.brand}
            </h2>

            <div className="flex items-center mt-1">
              {/* <div className="flex text-xl font-semibold">$112.00</div> */}
              <Prices
                contentClass="mt-1 py-1 px-2 text-sm font-semibold mr-2"
                price={product.price.toLocaleString()}
              />

              {/* <Link to="/product-navigation">
                <ButtonPrimary
                  className="mt-2 navigation-button"
                >
                  <BagIcon className="hidden sm:inline-block w-[0.2rem] h-[0.2rem] mb-0.5" />
                  <span className="ml-1 text-black text-xs">นำทางไปสินค้า</span>
                </ButtonPrimary>
              </Link> */}

              {/* <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div> */}

              {/* <div className="flex items-center">
                <a
                  href="#reviews"
                  className="flex items-center text-sm font-medium"
                >
                  <StarIcon className="w-5 h-5 pb-[1px] text-yellow-400" />
                  <div className="ml-1.5 flex">
                    <span>4.9</span>
                    <span className="block mx-2">·</span>
                    <span className="text-slate-600 dark:text-slate-400 underline">
                      142 reviews
                    </span>
                  </div>
                </a>
                <span className="hidden sm:block mx-2.5">·</span>
                <div className="hidden sm:flex items-center text-sm">
                  <SparklesIcon className="w-3.5 h-3.5" />
                  <span className="ml-1 leading-none">{status}</span>
                </div>
              </div> */}
            </div>
          </div>

          {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
          {/* <div className="">{renderVariants()}</div> */}
          {/* {product.name === "ทีวี" &&
            <div className="">{renderSizeList()}</div>
          } */}



          {/*  */}
          {/* <hr className="my-0 border-slate-200 dark:border-slate-700"></hr> */}
          {/*  */}

          {/* ---------- 5 ----------  */}
          <AccordionInfo product={product} />

          {/* ---------- 6 ----------  */}
          {/* <div className="hidden xl:block">
            <Policy />
          </div> */}
        </div>
      );
    }
  };

  const renderDetailSection = () => {
    return (
      <div className="">
        <h2 className="text-sm font-semibold">รายละเอียดสินค้า</h2>
        <div className="text-xs prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl mt-1">
          <p>
            เปิดประสบการณ์การรับชมความบันเทิงของคุณให้ถึงขีดสุดด้วยหน้าจอทีวีดีไซน์บางเฉียบผ่าน แอลอีดีทีวี จาก SAMSUNG เหนือชั้นด้วยเทคโนโลยี PurColor ให้ภาพที่สีสันสวยงามเป็นธรรมชาติ พร้อมมอบความสนุกให้กับคุณได้อย่างเต็มอิ่มไปกับเอฟเฟกต์เสียงรอบทิศทางโดยไม่ต้องปิดลำโพงทีวี เพื่อให้คุณได้รับชมคอนเทนต์โปรดผ่านทีวีได้อย่างเต็มอรรถรส และเพลิดเพลินไปกับความละเอียดภาพที่คมชัด
          </p>
          <ul>
            <li>ปรับเปลี่ยนและจัดการการแสดงเนื้อหาบนหน้าจอได้อย่างรวดเร็วภายในหน้าจอเดียว ผ่านระบบปฎิบัติการ TIZEN</li>
            <li>ภาพคมชัดระดับ 4K ความละเอียด 3,840 x 2,160 พิกเซล ด้วยชิพประมวลผล Crystal Processor 4K</li>
            <li>
              สีสดสมจริงด้วยเทคโนโลยี PurColor พร้อมเทคโนโลยี HDR ขับรายละเอียดภาพทั้งฉากมืดและสว่าง
            </li>
            <li>เข้าถึงคอนเทนต์ได้อย่างง่ายดายทั้ง Netflix และ Browser ต่าง ๆ ผ่านรีโมตที่มาพร้อมกันในเซ็ต</li>
            <li>มาพร้อม HDMI Version 2.0 และ USB จำนวน 1 ช่อง สำหรับการเชื่อมต่อที่หลากหลายยิ่งขึ้น</li>
            <li>พลังเสียงรวม 20 วัตต์ พร้อมมอบความชัดเจนในการฟังด้วยทิศทางเสียง 2.0 channel</li>
          </ul>
        </div>
      </div>
    );
  };

  useEffect(() => {
    let productJson = rawProducts.filter(e => e.id.toString() === id);
    // console.log("productJson", productJson)
    if (productJson) {
      let e = productJson[0]
      let images: string[] = []

      let findImages = productMedia.filter(media => (
        media.includes(e.id.toString()) && !media.includes(".mp4")
      ))
      findImages = findImages.map(e => e.replace("#", "%23"))
      console.log("findImages", findImages)
      if (findImages.length > 0) images = findImages

      if (images.length === 0) {
        images = []
        // if (e.category === "เครื่องซักผ้า") {
        //   images = [
        //     washing[Math.floor(Math.random() * washing.length)],
        //   ]
        // } else if (e.category === "ทีวี") {
        //   images = [
        //     tv[Math.floor(Math.random() * tv.length)],
        //     "https://backend.central.co.th/media/catalog/product/6/7/672c109cf31abbed041d46265a157eca364f4294_mkp1164057dummy.jpg?impolicy=resize&width=553",
        //     "https://backend.central.co.th/media/catalog/product/5/5/5522abf640b97d6a1a828c7d341f112b7042063b_mkp1164057dummy.jpg?impolicy=resize&width=553"
        //   ]
        // } else if (e.category === "ตู้เย็น") {
        //   images = [
        //     refrid[Math.floor(Math.random() * refrid.length)],
        //   ]
        // } else if (e.category === "เครื่องปรับอากาศ") {
        //   images = [
        //     air[Math.floor(Math.random() * air.length)],
        //   ]
        // }
      }

      let productObj: Product = {
        id: e.id.toString(),
        category: e.category,
        sub_category: e.sub_category,
        name: e.name,
        brand: e.brand,
        dimension: e.dimension,
        description: e.description,
        specification: e.specification,
        price: e.price ? parseInt(e.price.toString().replace(/[^0-9.-]+/g, "")) : 0.00,
        image: images,
      }
      // console.log("productObj", productObj)
      setProduct(productObj);
    }
  }, [id])

  return (
    <div className={`nc-ProductDetailPage ${className}`}>
      <div className="pt-2 pl-4"
        onClick={() => history.goBack()}
      >
        {renderBackIcon()}
      </div>
      {product &&
        <main className="container pt-3">
          <div className="flex">
            {/* CONTENT */}
            <div className="w-[55%] ">
              {/* HEADING */}
              <div className="bg-white rounded-md relative">
                <div className="aspect-w-16 aspect-h-12">
                  <img
                    src={product.image[0] ? product.image[0] : placeHolderImage}
                    className="w-full rounded-md object-contain"
                    alt="product detail 1"
                  />
                </div>
                {/* {renderStatus()} */}
                {/* META FAVORITES */}
                {/* <LikeButton className="absolute right-3 top-3 " /> */}
              </div>
              <div className="bg-white rounded-md grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-8 xl:mt-8">
                {[product.image[1], product.image[2]].map((item, index) => {
                  return (
                    <div
                      key={"img-" + index}
                      className="aspect-w-11 xl:aspect-w-10 2xl:aspect-w-11 aspect-h-8"
                    >
                      <img
                        src={item ? item : placeHolderImage}
                        className="w-full rounded-md object-contain"
                        alt="product detail 1"
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            {/* SIDEBAR */}
            <div className="w-[45%] pl-4">
              {renderSectionContent()}
            </div>
          </div>

          {/* DETAIL AND REVIEW */}
          <div className="mt-6 sm:mt-16 space-y-10 sm:space-y-16">
            {/* <div className="block xl:hidden">
              <Policy />
            </div> */}

            {/* {renderDetailSection()} */}

            {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

            {/* {renderReviews()} */}

            {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

            {/* OTHER SECTION */}
            {/* <SectionSliderProductCard
              heading="สินค้าที่คล้ายกัน"
              subHeading=""
              headingFontClassName="text-2xl font-semibold"
              headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
            /> */}

            {/* SECTION */}
            <div className="pb-1"></div>
            {/* <div className="pb-20 xl:pb-28 lg:pt-14">
          <SectionPromo2 />
        </div> */}
          </div>
        </main>
      }


      {/* MODAL VIEW ALL REVIEW */}
      {/* <ModalViewAllReviews
        show={isOpenModalViewAllReviews}
        onCloseModalViewAllReviews={() => setIsOpenModalViewAllReviews(false)}
      /> */}
    </div>
  );
};

export default ProductDetailPage;
