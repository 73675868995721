import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import LangDropdown from "./LangDropdown";
import AvatarDropdown from "./AvatarDropdown";
import TemplatesDropdown from "./TemplatesDropdown";
import DropdownCategories from "./DropdownCategories";
import CartDropdown from "./CartDropdown";
import { XMarkIcon } from "@heroicons/react/24/outline";

export interface MainNav2Props {
  className?: string;
}

const MainNav2: FC<MainNav2Props> = ({ className = "" }) => {
  const [showSearchForm, setShowSearchForm] = useState(false);

  useEffect(() => {
    // console.log(window.location.toString())
    // console.log(window.location.pathname.indexOf("/"))
  }, [])

  const renderHomeIcon = () => {
    return (
      // <svg
      //   width={22}
      //   height={22}
      //   viewBox="0 0 24 24"
      //   fill="none"
      //   xmlns="http://www.w3.org/2000/svg"
      // >
      //   <path
      //     d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
      //     stroke="currentColor"
      //     strokeWidth="1.5"
      //     strokeLinecap="round"
      //     strokeLinejoin="round"
      //   />
      //   <path
      //     d="M22 22L20 20"
      //     stroke="currentColor"
      //     strokeWidth="1.5"
      //     strokeLinecap="round"
      //     strokeLinejoin="round"
      //   />
      // </svg>
      <svg xmlns="http://www.w3.org/2000/svg" fill="white" width={16} height={16} version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
        <g><g><path d="M739.3,990H616.7c-12.8,0-23.2-10.4-23.2-23.2V751.7c0-27.9-22.7-50.5-50.5-50.5h-86c-27.9,0-50.5,22.7-50.5,50.5v215.1c0,12.8-10.4,23.2-23.2,23.2H260.1c-53.5,0-96.9-43.5-96.9-96.9V556c-28.2,1.2-73.8,2.3-73.9,2.3c-6.8,0.8-13-2.1-17.6-6.8c-39.4-39.4-39.6-116.6-0.5-155.8L428.4,38.6c38.1-38.1,104.5-38.1,142.7,0l357.1,357.1c39.5,39.6,39.7,116.8,0.6,155.8c-4.6,4.7-10.8,7.6-17.6,6.8c-0.1,0-45.8-2.7-74.9-2.3v337.1C836.2,946.5,792.8,990,739.3,990z M639.9,943.6h99.5c27.9,0,50.5-22.7,50.5-50.5V535.2c0-25.6,27.1-25.6,48.9-25.6c20.4,0,47.7,1.1,63.1,1.8c15.2-23.5,12.9-63.5-6.5-83L538.3,71.4c-20.6-20.5-56.5-20.5-77.1,0L104.1,428.5c-19.2,19.2-21,59.4-5.5,83c15.2-0.7,42.2-1.8,62.4-1.8c21.7,0,48.6,0,48.6,25.5v357.9c0,27.9,22.7,50.5,50.5,50.5H360V751.8c0-53.4,43.5-96.9,96.9-96.9h86c53.4,0,96.9,43.5,96.9,96.9L639.9,943.6L639.9,943.6L639.9,943.6z" /></g><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /></g>
      </svg>
    );
  };

  const renderSearchForm = () => {
    return (
      <form
        action=""
        method="POST"
        className="flex-1 py-2 text-slate-900 dark:text-slate-100"
      >
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
          {renderHomeIcon()}
          <input
            type="text"
            placeholder="Type and press enter"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            autoFocus
          />
          <button onClick={() => setShowSearchForm(false)}>
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
        <input type="submit" hidden value="" />
      </form>
    );
  };

  return (
    <div className="nc-MainNav2Logged relative z-10 dark:bg-slate-900 ">
      <div className="container">
        <div className="h-10 flex justify-between">
          {/* <div className="flex items-center md:hidden flex-1">
            <MenuBar />
          </div> */}

          <div className="flex lg:flex-1 items-center space-x-3 sm:space-x-8">
            <Logo />
            {!showSearchForm && (
              <div className="hidden md:block h-10 border-l border-slate-200 dark:border-slate-700"></div>
            )}
            {!showSearchForm && (
              // <div className="hidden md:block">
              //   <DropdownCategories />
              // </div>
              <DropdownCategories />
            )}
          </div>

          {/* {showSearchForm && (
            <div className="flex-[2] flex !mx-auto px-10">
              {renderSearchForm()}
            </div>
          )} */}

          <div className="flex-2 flex items-center justify-end">
            {(!((window.location.pathname.indexOf("/") + 1) % (window.location.pathname.lastIndexOf("/") + 1) === 0) ||
              window.location.pathname.includes("/product-navigation") ||
              window.location.toString().includes("?")) &&
              <div
                className="flex text-sm w-20 h-12 rounded-full items-center justify-end"
                onClick={() => window.location.replace("/")}
              >
                <div className="text-white mr-1">กลับหน้าแรก</div>
                {renderHomeIcon()}
              </div>
            }

            {/* {!showSearchForm && <TemplatesDropdown />} */}
            {/* {!showSearchForm && <LangDropdown />} */}
            {/* {!showSearchForm && (
              <button
                className="hidden lg:flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none items-center justify-center"
                onClick={() => setShowSearchForm(!showSearchForm)}
                disabled
              >
                {renderHomeIcon()}
              </button>
            )} */}
            {/* <AvatarDropdown /> */}
            {/* <CartDropdown /> */}
            {/* <MenuBar /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav2;
