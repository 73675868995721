import * as fs from 'fs';

import { productImgs } from "contains/fakeData";
import productVariantImg2 from "images/products/v2.jpg";
import productVariantImg3 from "images/products/v3.jpg";
import productVariantImg4 from "images/products/v4.jpg";
import productVariantImg5 from "images/products/v5.jpg";
import productVariantImg6 from "images/products/v6.jpg";

import productSport1 from "images/products/sport-1.png";

import catAirPng from "images/collections/cat-air-conditioner.png";
import catRefrigeratorPng from "images/collections/cat-refrigerator.png";
import catWashingPng from "images/collections/cat-washing-machine.png";
import catTelevisionPng from "images/collections/cat-television.png";

import explore1Svg from "images/collections/explore1.svg";
import explore4Svg from "images/collections/explore4.svg";
import explore8Svg from "images/collections/explore8.svg";
import explore9Svg from "images/collections/explore9.svg";
import { QUESTION_AIR, QUESTION_REFRIGERATOR, QUESTION_TV, QUESTION_WASHING_MACHINE } from "./questions";

export interface ProductVariant {
  id: number;
  name: string;
  thumbnail?: string;
  color?: string;
  featuredImage: string;
}
export interface Product {
  id: string;
  category: string;
  sub_category?: string;
  name: string;
  brand?: string;
  dimension?: string;
  description: string;
  specification?: string;
  price: number;
  image: string[];

  tags?: string[];
  link?: "/product-detail/";
  variants?: ProductVariant[];
  variantType?: "color" | "image";
  sizes?: string[];
  allOfSizes?: string[];
  status?: "New in" | "limited edition" | "Sold Out" | "50% Discount";
}

export interface ProductCategories {
  id: number;
  name: string;
  description: string;
  long_description?: string;
  href?: string;
  icon: any;
  active?: boolean;
  color?: string;
  image?: string;
  svgBg?: string;
  question?: any;
}

export const CATEGORIES: ProductCategories[] = [
  {
    id: 1,
    name: "ทีวี",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="#e2221c" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 502 502" xml:space="preserve">
    <g>
      <g>
        <g>
          <path d="M492,56.5H10c-5.523,0-10,4.477-10,10v318c0,5.523,4.477,10,10,10h52.309l-22.738,35.619     c-1.965,3.079-2.096,6.984-0.34,10.187c1.755,3.203,5.117,5.194,8.77,5.194h406c3.652,0,7.014-1.991,8.77-5.194     c1.755-3.203,1.625-7.108-0.341-10.187l-12.001-18.8c-2.972-4.655-9.155-6.02-13.81-3.048c-4.655,2.972-6.02,9.154-3.048,13.81     l2.183,3.419H66.248l19.79-31H224v4c0,5.523,4.477,10,10,10h34c5.523,0,10-4.477,10-10v-4h214c5.523,0,10-4.477,10-10v-318     C502,60.977,497.523,56.5,492,56.5z M482,374.5H20v-298h462V374.5z"/>
          <path d="M48,331.5c5.523,0,10-4.477,10-10v-161c0-5.523-4.477-10-10-10s-10,4.477-10,10v161C38,327.023,42.477,331.5,48,331.5z"/>
          <path d="M48,134.5c5.523,0,10-4.477,10-10v-19c0-5.523-4.477-10-10-10s-10,4.477-10,10v19C38,130.023,42.477,134.5,48,134.5z"/>
        </g>
      </g>
    </g>
    </svg>
    `,
    description: "อัพเกรดจอที่ดีกว่า",
    long_description: "บ้าน > เครื่องใช้ไฟฟ้าในบ้าน > ทีวีและอุปกรณ์บันเทิงภายในบ้าน",
    color: "bg-indigo-50",
    image: catTelevisionPng,
    svgBg: explore1Svg,
    href: "tv",
    question: QUESTION_TV,
  },
  {
    id: 2,
    name: "เครื่องซักผ้า",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="#e2221c" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 463 463" xml:space="preserve">
    <g>
      <path d="M383.5,0h-304C66.542,0,56,10.542,56,23.5v416c0,12.958,10.542,23.5,23.5,23.5h304c12.958,0,23.5-10.542,23.5-23.5v-416   C407,10.542,396.458,0,383.5,0z M71,95h321v321H71V95z M392,23.5V80H183V15h200.5C388.187,15,392,18.813,392,23.5z M79.5,15H168v65   H71V23.5C71,18.813,74.813,15,79.5,15z M383.5,448h-304c-4.687,0-8.5-3.813-8.5-8.5V431h321v8.5C392,444.187,388.187,448,383.5,448   z"/>
      <path d="M231.5,128C170.019,128,120,178.019,120,239.5S170.019,351,231.5,351S343,300.981,343,239.5S292.981,128,231.5,128z    M231.5,336c-53.21,0-96.5-43.29-96.5-96.5s43.29-96.5,96.5-96.5s96.5,43.29,96.5,96.5S284.71,336,231.5,336z"/>
      <path d="M231.5,71c12.958,0,23.5-10.542,23.5-23.5S244.458,24,231.5,24S208,34.542,208,47.5S218.542,71,231.5,71z M231.5,39   c4.687,0,8.5,3.813,8.5,8.5s-3.813,8.5-8.5,8.5s-8.5-3.813-8.5-8.5S226.813,39,231.5,39z"/>
      <path d="M95.5,71h48c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-48c-4.142,0-7.5,3.358-7.5,7.5S91.358,71,95.5,71z"/>
      <path d="M279.5,55h24c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-24c-4.142,0-7.5,3.358-7.5,7.5S275.358,55,279.5,55z"/>
      <path d="M335.5,55h24c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-24c-4.142,0-7.5,3.358-7.5,7.5S331.358,55,335.5,55z"/>
      <path d="M231.5,160c-43.836,0-79.5,35.664-79.5,79.5s35.664,79.5,79.5,79.5s79.5-35.664,79.5-79.5S275.336,160,231.5,160z    M231.5,304c-35.565,0-64.5-28.935-64.5-64.5s28.935-64.5,64.5-64.5s64.5,28.935,64.5,64.5S267.065,304,231.5,304z"/>
    </g>
    </svg>
    `,
    description: "ล้างให้สะอาด",
    long_description: "บ้าน > เครื่องใช้ไฟฟ้าในบ้าน > เครื่องซักผ้า",
    color: "bg-slate-100/80",
    image: catWashingPng,
    svgBg: explore8Svg,
    href: "washing-machine",
    question: QUESTION_WASHING_MACHINE,
  },
  {
    id: 3,
    name: "ตู้เย็น",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="#e2221c" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 490.561 490.561" xml:space="preserve">
    <g>
      <g>
        <g>
          <path d="M356.214,0H134.24c-18.133,0-32.96,14.72-32.96,32.853v424.853c0,18.24,14.827,32.96,32.96,32.853h221.973     c18.24,0,32.96-14.72,33.067-32.853V32.853C389.28,14.72,374.454,0,356.214,0z M367.947,457.813c0,6.4-5.227,11.52-11.733,11.52     H134.24c-6.4,0-11.627-5.12-11.627-11.52V202.667h245.333V457.813z M367.947,181.333H122.614V32.853     c0-6.4,5.227-11.52,11.627-11.52h221.973c6.4,0,11.627,5.12,11.733,11.52V181.333z"/>
          <path d="M335.947,85.333c-5.867,0-10.667,4.8-10.667,10.667v42.667c0,5.867,4.8,10.667,10.667,10.667     c5.867,0,10.667-4.8,10.667-10.667V96C346.614,90.133,341.814,85.333,335.947,85.333z"/>
          <path d="M335.947,298.667c5.867,0,10.667-4.8,10.667-10.667v-53.333c0-5.867-4.8-10.667-10.667-10.667     c-5.867,0-10.667,4.8-10.667,10.667V288C325.28,293.867,330.08,298.667,335.947,298.667z"/>
        </g>
      </g>
    </g>
    </svg>
     `,
    description: "เก็บได้ยาวนาน",
    long_description: "บ้าน > เครื่องใช้ไฟฟ้าในบ้าน > ตู้เย็น",
    color: "bg-violet-50",
    image: catRefrigeratorPng,
    svgBg: explore9Svg,
    href: "refrigerator",
    question: QUESTION_REFRIGERATOR,
  },
  {
    id: 4,
    name: "เครื่องปรับอากาศ",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="#e2221c" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 206.816 206.816" xml:space="preserve">
    <g>
      <g>
        <g>
          <path d="M188.97,28.527H17.847C8.006,28.527,0,36.54,0,46.391v49.852c0,9.851,8.006,17.864,17.847,17.864H188.97     c9.841,0,17.846-8.013,17.846-17.864V46.391C206.817,36.54,198.811,28.527,188.97,28.527z M199.685,96.243     c0,5.916-4.807,10.732-10.715,10.732H17.847c-5.908,0-10.715-4.816-10.715-10.732V46.391c0-5.916,4.807-10.732,10.715-10.732     H188.97c5.908,0,10.715,4.816,10.715,10.732V96.243z"/>
          <path d="M162.982,143.678c-1.393-1.393-3.649-1.393-5.042,0l-6.087,6.087h-2.746c-1.077-3.021-3.454-5.398-6.474-6.474v-2.746     l6.087-6.087c1.393-1.393,1.393-3.649,0-5.042s-3.649-1.393-5.042,0l-4.61,4.61l-4.611-4.611c-1.393-1.393-3.649-1.393-5.042,0     c-1.393,1.393-1.393,3.649,0,5.042l6.087,6.087v2.746c-3.021,1.077-5.398,3.454-6.474,6.474h-2.746l-6.087-6.087     c-1.393-1.393-3.649-1.393-5.042,0s-1.393,3.649,0,5.042l4.61,4.61l-4.611,4.611c-1.393,1.393-1.393,3.649,0,5.042     c0.697,0.696,1.609,1.044,2.521,1.044s1.825-0.348,2.521-1.045l6.087-6.087h2.747c1.077,3.021,3.453,5.397,6.474,6.474v2.747     l-6.087,6.087c-1.393,1.393-1.393,3.649,0,5.042s3.649,1.393,5.042,0l4.61-4.611l4.611,4.611     c0.696,0.696,1.609,1.044,2.521,1.044c0.912,0,1.825-0.348,2.521-1.045c1.393-1.393,1.393-3.649,0-5.042l-6.087-6.087v-2.746     c3.021-1.077,5.398-3.454,6.474-6.474h2.746l6.087,6.087c0.697,0.696,1.609,1.045,2.521,1.045s1.825-0.348,2.521-1.045     c1.393-1.393,1.393-3.649,0-5.042l-4.61-4.61l4.611-4.611C164.376,147.327,164.376,145.071,162.982,143.678z M139.067,156.896     c-1.966,0-3.566-1.598-3.566-3.566c0-1.968,1.6-3.566,3.566-3.566s3.566,1.598,3.566,3.566     C142.632,155.298,141.032,156.896,139.067,156.896z"/>
          <path d="M88.1,143.678c-1.393-1.393-3.649-1.393-5.042,0l-6.087,6.087h-2.746c-1.077-3.021-3.454-5.398-6.474-6.474v-2.746     l6.087-6.087c1.393-1.393,1.393-3.649,0-5.042s-3.649-1.393-5.042,0l-4.61,4.61l-4.611-4.611c-1.393-1.393-3.649-1.393-5.042,0     s-1.393,3.649,0,5.042l6.087,6.087v2.746c-3.021,1.077-5.398,3.454-6.474,6.474H51.4l-6.087-6.087     c-1.393-1.393-3.649-1.393-5.042,0s-1.393,3.649,0,5.042l4.61,4.61l-4.611,4.611c-1.393,1.393-1.393,3.649,0,5.042     c0.697,0.696,1.609,1.044,2.521,1.044s1.825-0.348,2.521-1.045l6.087-6.087h2.747c1.077,3.021,3.453,5.397,6.474,6.474v2.747     l-6.087,6.087c-1.393,1.393-1.393,3.649,0,5.042s3.649,1.393,5.042,0l4.61-4.611l4.611,4.611     c0.696,0.696,1.609,1.044,2.521,1.044s1.825-0.348,2.521-1.045c1.393-1.393,1.393-3.649,0-5.042l-6.087-6.087v-2.746     c3.021-1.077,5.398-3.454,6.474-6.474h2.746l6.087,6.087c0.697,0.696,1.609,1.045,2.521,1.045c0.912,0,1.825-0.348,2.521-1.045     c1.393-1.393,1.393-3.649,0-5.042l-4.61-4.61l4.611-4.611C89.494,147.327,89.494,145.071,88.1,143.678z M64.185,156.896     c-1.966,0-3.566-1.598-3.566-3.566c0-1.968,1.6-3.566,3.566-3.566s3.566,1.598,3.566,3.566     C67.75,155.298,66.15,156.896,64.185,156.896z"/>
          <rect x="35.658" y="89.146" width="131.986" height="7.132"/>
          <rect x="21.395" y="78.449" width="164.027" height="7.132"/>
        </g>
      </g>
    </g>
    </svg>
     `,
    description: "พร้อมรับอากาศร้อน",
    long_description: "บ้าน > เครื่องใช้ไฟฟ้าในบ้าน > เครื่องปรับอากาศ",
    color: "bg-orange-50",
    image: catAirPng,
    svgBg: explore4Svg,
    href: "air-conditioner",
    question: QUESTION_AIR,
  },
  // {
  //   id: 5,
  //   name: "สินค้าอื่นๆ",
  //   icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="#e2221c" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 502 502" xml:space="preserve">
  //   <g>
  //     <g>
  //       <g>
  //         <path d="M159,171h303c22.056,0,40-17.944,40-40c0-22.056-17.944-40-40-40H159c-22.056,0-40,17.944-40,40     C119,153.056,136.944,171,159,171z M159,111h303c11.028,0,20,8.972,20,20s-8.972,20-20,20H159c-11.028,0-20-8.972-20-20     S147.972,111,159,111z"/>
  //         <path d="M462,211H159c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40h303c22.056,0,40-17.944,40-40     C502,228.944,484.056,211,462,211z M462,271H159c-11.028,0-20-8.972-20-20s8.972-20,20-20h303c11.028,0,20,8.972,20,20     S473.028,271,462,271z"/>
  //         <path d="M462,331H159c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40h303c22.056,0,40-17.944,40-40     C502,348.944,484.056,331,462,331z M462,391H159c-11.028,0-20-8.972-20-20s8.972-20,20-20h303c11.028,0,20,8.972,20,20     S473.028,391,462,391z"/>
  //         <path d="M49,82c-27.019,0-49,21.981-49,49s21.981,49,49,49s49-21.981,49-49S76.019,82,49,82z M49,160c-15.991,0-29-13.009-29-29     c0-15.991,13.009-29,29-29s29,13.009,29,29C78,146.991,64.991,160,49,160z"/>
  //         <path d="M49,202c-27.019,0-49,21.981-49,49c0,27.019,21.981,49,49,49s49-21.981,49-49S76.019,202,49,202z M49,280     c-15.991,0-29-13.009-29-29c0-15.991,13.009-29,29-29s29,13.009,29,29C78,266.991,64.991,280,49,280z"/>
  //         <path d="M49,322c-27.019,0-49,21.981-49,49c0,27.019,21.981,49,49,49s49-21.981,49-49C98,343.981,76.019,322,49,322z M49,400     c-15.991,0-29-13.009-29-29c0-15.991,13.009-29,29-29s29,13.009,29,29C78,386.991,64.991,400,49,400z"/>
  //         <path d="M216,139h171c5.523,0,10-4.477,10-10s-4.477-10-10-10H216c-5.523,0-10,4.477-10,10S210.477,139,216,139z"/>
  //         <path d="M158,139h18c5.523,0,10-4.477,10-10s-4.477-10-10-10h-18c-5.523,0-10,4.477-10,10S152.477,139,158,139z"/>
  //       </g>
  //     </g>
  //   </g>
  //   </svg>
  //    `,
  //   description: "กาต้มน้ำ, เตาไฟฟ้า, ...",
  //   color: "bg-blue-50",
  //   image: catVacuumPng,
  //   svgBg: explore5Svg,
  // },
];

const DEMO_VARIANTS: ProductVariant[] = [
  {
    id: 1,
    name: "Black",
    thumbnail: productVariantImg6,
    featuredImage: productImgs[0],
  },
  {
    id: 2,
    name: "White",
    thumbnail: productVariantImg2,
    featuredImage: productImgs[1],
  },
  {
    id: 3,
    name: "Orange",
    thumbnail: productVariantImg3,
    featuredImage: productImgs[2],
  },
  {
    id: 4,
    name: "Sky Blue",
    thumbnail: productVariantImg4,
    featuredImage: productImgs[3],
  },
  {
    id: 5,
    name: "Natural",
    thumbnail: productVariantImg5,
    featuredImage: productImgs[4],
  },
];
const DEMO_VARIANT_COLORS: ProductVariant[] = [
  {
    id: 1,
    name: "Violet",
    color: "bg-violet-400",
    featuredImage: productImgs[0],
  },
  {
    id: 2,
    name: "Yellow",
    color: "bg-yellow-400",
    featuredImage: productImgs[1],
  },
  {
    id: 3,
    name: "Orange",
    color: "bg-orange-400",
    featuredImage: productImgs[2],
  },
  {
    id: 4,
    name: "Sky Blue",
    color: "bg-sky-400",
    featuredImage: productImgs[3],
  },
  {
    id: 5,
    name: "Green",
    color: "bg-green-400",
    featuredImage: productImgs[4],
  },
];

export const PRODUCTS: Product[] = [
  {
    id: "1",
    name: "65” Neo QLED 4K QN85B ",
    description: "Brown cockroach wings",
    price: 13900,
    image: [productImgs[16]],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/product-detail/",
    variants: DEMO_VARIANTS,
    variantType: "image",
    sizes: [`32"`, `40"`, `52"`, `60"`],
    allOfSizes: [`32"`, `40"`, `52"`, `60"`],
    status: "New in",
  },
  {
    id: "2",
    name: 'ACONATIC 42HS600AN',
    description: "Classic green",
    price: 14900,
    image: [productImgs[1]],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/product-detail/",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    status: "50% Discount",
  },
  {
    id: "3",
    name: "ACONATIC 50US200AN",
    description: "New blue aqua",
    price: 18900,
    image: [productImgs[15]],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/product-detail/",
    variants: DEMO_VARIANTS,
    variantType: "image",
    sizes: [`32"`, `40"`, `52"`, `60"`],
    allOfSizes: [`32"`, `40"`, `52"`, `60"`],
  },
];

export const SPORT_PRODUCTS: Product[] = [
  {
    id: "1",
    name: "Mastermind Toys",
    description: "Brown cockroach wings",
    price: 74,
    image: [productSport1],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/product-detail/",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    sizes: ["XS", "S", "M", "L", "XL"],
    allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
    status: "New in",
  }
];
