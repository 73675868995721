import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import MyRouter from "routers/index";
import chatbotSdk from "./chatbot/cloudia-sdk-v1.4.1.4-iframe";

import "./App.css";
function App() {

  useEffect(() => {
    chatbotSdk.init((cmd: any, params: any) => {
      console.log(`receive cmd=${cmd},params=${params}`);
    });
  }, []);

  return (
    <>
      <div>
        <Toaster />
      </div>
      {/* MAIN APP */}
      <div className="app bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
        {/* <button onClick={() => chatbotSdk.enterImmerseMode(true)}>CHATBOT</button> */}
        <MyRouter />
      </div>
    </>
  );
}

export default App;
